<template>
    <div>
        <Loader v-if="isLoading"/>

        <st-page :title="$t('TAX_CATEGORY.LIST.TITLE')">
            <template #toolbar>
                <st-button
                    v-can:create="'taxCategory'"
                    variant="secondary"
                    :callback="openAddModal"
                >
                    <i class="fa fa-plus"></i>
                    {{ $t('TAX_CATEGORY.ADD_BUTTON') }}
                </st-button>
            </template>

            <tax-category-list-filter
                @params="doFilter"
            >
                <tax-category-list-table
                    @edit="openEditModal"
                    @delete="deleteTaxCategory"
                />
            </tax-category-list-filter>
        </st-page>

        <add-tax-category-modal
            ref='add-tax-category-modal'
            @submit="submitAddTaxCategory"
            :loading="isLoadingAdd"
        />

        <edit-tax-category-modal
            ref='edit-tax-category-modal'
            :selected-item="selectedItem"
            @submit="submitEditTaxCategory"
            :loading="isLoadingEdit"
        />
    </div>
</template>

<script>
import TaxCategoryListFilter from '../components/TaxCategoryListFilter';
import TaxCategoryListTable from '../components/TaxCategoryListTable';
import AddTaxCategoryModal from '../components/AddTaxCategoryModal';
import EditTaxCategoryModal from '../components/EditTaxCategoryModal';

import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TaxCategory',
    components: {
        TaxCategoryListFilter,
        TaxCategoryListTable,
        AddTaxCategoryModal,
        EditTaxCategoryModal,
    },
    data() {
        return {
            params: {},
            selectedItem: {},
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        isLoading() {
            return this.loading['taxCategory/get']
                || this.loading['taxCategory/remove'];
        },
        isLoadingAdd() {
            return !!this.loading['taxCategory/create'];
        },
        isLoadingEdit() {
            return !!this.loading['taxCategory/update'];
        },
    },
    methods: {
        ...mapActions({
            doFetch: 'taxCategory/list/doFetch',
            add: 'taxCategory/form/create',
            update: 'taxCategory/form/update',
            remove: 'taxCategory/form/remove',
        }),
        refresh() {
            this.doFetch(this.params);
        },
        doFilter(params) {
            this.params = params;
            this.refresh();
        },
        openAddModal() {
            this.$refs['add-tax-category-modal'].show();
        },
        openEditModal(item) {
            this.selectedItem = item;
            this.$refs['edit-tax-category-modal'].show();
        },
        async submitAddTaxCategory(item) {
            await this.add(item);
            this.$refs['add-tax-category-modal'].hide();
            this.refresh();
        },
        async submitEditTaxCategory(item) {
            await this.update({ id: this.selectedItem.id, values: item });
            this.$refs['edit-tax-category-modal'].hide();
            this.refresh();
        },
        async deleteTaxCategory(item) {
            await this.remove(item.id);
            this.refresh();
        },
    },
}
</script>
